:root{
    --progress: 0;
}
html{
    font-size: 10px;
}
.ibm-plex-sans-condensed-regular {
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-weight: 400;
    font-style: normal;
}
  
.ibm-plex-sans-condensed-bold {
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-weight: 700;
    font-style: normal;
}
.navbar{
    box-shadow: rgba(0, 0, 0, .2) 0 0 8px;
    backdrop-filter: blur(24px);
    --bs-bg-opacity: .5;
    &-nav{
        text-transform: uppercase;
        font-size: 1.3rem;
        a{
            color: inherit;
        }
    }
    &-brand{
        font-size: 16px;
        letter-spacing: 1px;
    }
}

.h100{
    height: calc(100vh - 38px);
}
.z{
    &-1{
        z-index: 1;
    }
}

h2{
    font-weight: 400;
}
h3{
    font-size: 2.6rem;
    color: $green;
}
ul, li{
    padding: 0;
    margin: 0;
    list-style: none;
}
a,p{
    font-size: 1.6rem;
    font-weight: 400;
}
a{
    color: $white;
    &:hover{
        color: $yellow;
    }
    /* Estilo para el enfoque de los enlaces */
    &:focus {
        outline: 2px dashed $yellow;
        outline-offset: 4px;
    }
}
figure{
    figcaption{
        font-size: 1.4rem;
    }
    &.round{
        img{
            border-radius: 0 0 32px 32px;
        }
    }
}
.blen{
    mix-blend-mode: color-dodge;
}

article{
    h2{
        font-size: 4.2rem;
        line-height: 110%;
        a{
            font-size: inherit;
            color: $orange;
            text-decoration: none;
        }
    }
    figure{
        transition: all ease-out .5s;
        opacity: .7;
        filter: grayscale(1);
    }
    &:hover{
        figure{
            opacity: 1;
            filter: grayscale(0);
            &:after{
                opacity: 0;
            }
        }
    }
}

section{
    &.no-results, &.not-found{
        h1{
            font-size: 9.6rem;
            line-height: 92%;
            letter-spacing: -2px;
        }
    }
}


.section{
    &-head{
        opacity: 0;
        span{
            font-size: 1.6rem;
            text-transform: uppercase;
        }
        h2{
            font-size: 9.6rem;
            line-height: 110%;
        }
        /*opacity: 0;
        transition: all ease-out .3s;
        transform: translate3d(0, 60px, 0);
        transition: all ease-out .3s;*/
    }
    margin-top: 16srem;
    margin-bottom: 16rem;
    &-service{
        a{
            color: $white;
        }
        .w-25{
            opacity: 0;
            /*transform: translate3d(0, 80px, 0);
            transition: all ease-out .6s;*/
        }
    }
}

footer{
    color: $purple;
    border-right: none;
    border-left: none;
    h2{
        font-size: 9.6rem;
        line-height: normal;
    }
    h3{
        color: inherit;
        font-size: 2rem;
    }
    p{
        font-size: 1.4rem;
    }
    ul{
        li{
            a{
                font-size: 1.8rem;
                color: inherit;
                margin-right: .5rem;
            }
        }
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 80px;
        background-color: $white;
    }
}

.home{
    .cite{
        position: relative;
        h2{
            span{
                font-size: 9.6rem;
                font-weight: 600;
                color: $green;
            }
            line-height: 130%;
        }
        a{
            color: $green;
        }
    }
    canvas{
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
    }
   article{
        figure{
            height: 320px;
            img{
                height: 100%;
                object-fit: cover;
            }
        }
   }
}
/*decorations*/
.line{
    height: 100%;
    &-bsic{
        display: block;
        mix-blend-mode:lighten;
        opacity: .9;
        border-radius: 100px;
    }
    &-big{
        background-color: $purple;
        height: 80vh;
        border-radius: 0 0 200px 200px;
        mix-blend-mode: color;
        border-left: 1px solid rgba(255, 255, 255, .5);
        z-index: 1;
    }
    &-medium{
        width: 80px;
        height: 375px;
        &-orange{
            background: linear-gradient(180deg, rgba(240,140,52,1) 20%, rgba(240,140,52,0.10127801120448177) 100%);
            z-index: 10;
            border-left: 1px solid rgba(255, 255, 255, .15);
        }
        &-purple{
            background-color: $purple;
            left: -40px !important;
            border-left: 1px solid rgba(255, 255, 255, .15);
        }
        &-green{
            background: linear-gradient(180deg, rgba(73,197,182,1) 20%, rgba(73,197,182,0.10127801120448177) 100%);
            opacity: 1;
            top: -40px !important;
        }
        &-greenfade{
            background: linear-gradient(180deg, rgba(73,197,182,1) 20%, rgba(73,197,182,0.10127801120448177) 100%) !important;
        }
    }
    &-xl{
        width: 32px;
        height: 420px;
        background: linear-gradient(180deg, rgba(122,85,231,1) 20%, rgba(122,85,231,0) 100%);
    }
    &-fix{
        top: -80px !important;
        left: -60px !important;
    }
    &-lb{
        left: 82% !important;
        top: 20px !important;
    }
}
.diag{
    display: block;
    width: 100%;
    z-index: 0;
    &-purple{
        transform: skew(0deg,-5deg) translate3d(0px, calc(var(--progress)*50vh), 0px);
        height: 300px;
        mix-blend-mode: color-dodge;
        background: rgb(122,85,231);
        background: linear-gradient(180deg, rgba(122,85,231,1) 0%, rgba(122,85,231,0.15) 100%);
    }
    &-blue{
        transform: skew(0deg,4deg) translate3d(0,calc(var(--progress) * -20vh),0);
        height: 400px;
        mix-blend-mode: color-burn;
        background: rgb(73,93,230);
        background: linear-gradient(180deg, rgba(73,93,230,1) 0%, rgba(73,93,230,0.15) 100%);
        top: 14rem !important;
    }
    &-red{
        transform: skew(0deg, -5deg) translate3d(0,calc(var(--progress) * 20vh),0);
        height: 300px;
        mix-blend-mode: color-dodge;
        background: rgb(236,91,85);
        background: linear-gradient(180deg, rgba(236,91,85,1) 0%, rgba(236,91,85,0.1513991013071896) 100%);
    }
    &-orange{
        height: 160px;
        mix-blend-mode: color-burn;
        transform: skew(0deg, 4deg) translate3d(0,calc(var(--progress) * -50vh),0);
        background: rgb(73,93,230);
        background: linear-gradient(180deg, rgba(73,93,230,1) 0%, rgba(73,93,230,0.15) 100%);
        top: 20rem !important;
    }
    &-green{
        transform: skew(0deg,-3deg) translate3d(0px, calc(var(--progress)*-20vh), 0px);
        height: 300px;
        z-index: -1;
        background: rgb(73, 197, 182);
        background: linear-gradient(180deg, rgba(73, 197, 182,1) 0%, rgba(73, 197, 182,0.15) 100%);
    }
    &-white{
        transform: skew(0deg,2deg);
        width: 100%;
        height: 400px;
        background: $white;
    }
}



.single{
    &-leo{
        /*padding-top: 73vh;*/
        .title{
            margin-bottom: 6rem;
            margin-left: 6rem;
        }
        ul{
            padding-left: 2rem;
            margin: 2rem 0;
            li{
                font-size: 1.5rem;
                list-style: disc;
            }
        }
        .full-width-header {
            position: relative;
            width: 100%;
            height: 70vh;
            .bg-hero{
                position: absolute;
                top: 0;
                left: 0;
                width:  calc(100vw - map-get($container-max-widths, "xxl") + 53vw );
                height: 100%;
                background-image: var(--featured-image-url);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 50% 50%;
                border-radius: 0 0 0 8px;
                border-left: solid 1px $purple;
                z-index: -1;
            }
            h1{
                font-size: 9.6rem;
                line-height: 92%;
                letter-spacing: -2px;
                font-weight: 700;
            }
            span{
                font-size: 1.2rem;
                text-transform: uppercase;
            }
        }
    }
    .aside{
        text-align: right;
        .wr{
            z-index: 99;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 2.5rem 2rem 1.5rem 2rem;
            border-left: 1px solid rgba(255, 255, 255, .15);
            h4{
                font-weight: 700;
                font-size: 1.8rem;
            }
            ul{
                margin: 0;
                padding: 0;
                li{
                    font-size: 1.4rem;
                    line-height: normal;
                    list-style: none;
                    a{
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-end;
                        align-items: center;
                        color: inherit;
                        text-decoration: none;
                        &:hover{
                            color: $yellow;
                        }
                        img{
                            border-radius: 8px;
                            aspect-ratio: 1/1;
                        }
                    }
                }
            }
        }
    }
    
    .entry-content{
        h2{
            margin-bottom: 2rem;
        }
        figure{
            background-color: $white;
            padding: 1.5rem;
            padding-bottom: 1rem;
            margin-right: 2rem;
            width: max-content;
            img{
                border-radius: 8px;
            }
            figcaption{
                background-color: $white;
                padding: 1.5rem 1.5rem .5rem .0rem;
                margin: 0;
                font-size: 1.5rem;
                color: $body-bg;
            }
        }
    }
    .code-block{
        margin: 4rem auto;
        font-size: 1.5rem;
    }
    blockquote{
        padding: 4rem;
        padding-right: 3.5rem;
        padding-left: 14rem;
        background: #000;
        border-radius: 8px;
        border-left: $green solid 1px;
        margin: 3rem auto;
        width: 85%;
        box-shadow: rgba(0,0,0,.15) 0px 0px 24px;
        position: relative;
        p{
            font-size: 2.3rem;
            line-height: normal;
            margin-bottom: 2rem;
            &:after{
                content: '#';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(25%, -5%, 0);
                z-index: 1;
                font-size: 16rem;
                font-weight: 400;
                color: $green;
                font-family: "IBM Plex Mono", monospace;
            }
        }
        cite{
            color: $green;
            font-size: 1.5rem;
        }
    }
}


.wrapper{
    &-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-flow: dense;
        gap: 2rem;
        h2{
            font-size: 2rem;
        }
        article{
            background: $body-bg;
            box-sizing: border-box;
            break-inside: avoid;
            grid-column: span 1;
            border: 1px solid rgba(255, 255, 255, .15);
            transition: all .2s ease-out;
            border-radius: 8px;
            img{
                transition: all .3s ease-out;
                border-radius: 7px 7px 0 0;
                opacity: .7;
                filter: grayscale(1);
            }
            .entry-header{
                span{
                    font-size: 1.4rem;
                }
            }
            &:hover{
                border: 1px solid rgba(255, 255, 255, .5);
                box-shadow: rgba(0,0,0,0.5) 2px 2px 16px;
                img{
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        }
    }
}


/*custom componets*/
.flip-in-diag-1-bl {
	-webkit-animation: flip-in-diag-1-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
	        animation: flip-in-diag-1-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
}
@-webkit-keyframes flip-in-diag-1-bl {
    0% {
        -webkit-transform: rotate3d(1, 1, 0, 80deg);
                transform: rotate3d(1, 1, 0, 80deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
                transform: rotate3d(1, 1, 0, 0deg);
        opacity: 1;
    }
    }
    @keyframes flip-in-diag-1-bl {
    0% {
        -webkit-transform: rotate3d(1, 1, 0, 80deg);
                transform: rotate3d(1, 1, 0, 80deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
                transform: rotate3d(1, 1, 0, 0deg);
        opacity: 1;
    }
}
 
/**/

.btn{
    padding: 0.9375rem 2rem 1rem 2rem;
    text-decoration: none;
    font-size: 1.6rem;
    position: relative;
    &-green{
        &.btn-thing {
            border-color: $green;
            color: $green;
        }
    }
    &-round{
        border-radius: 24px;
    }
    &-thing{
        border-style: solid;
        border-width: 2px;
        z-index: 10;
        overflow: hidden;
        transition: all ease-out .2s;
        &:hover{
            color: $yellow;
            background-color: $body-bg;
        }
    }
}
.skip-link{
    position: absolute;
    left: 0;
    top: 0;
    background: $yellow;
    color: $body-bg;
    padding: 1rem 1.5rem;
    border-radius: 0 0 8px 0;
    z-index: 9999;
    &:hover{
        color: $purple;
    }
}
.carousel-indicators {
    top: 6px;
    justify-content: end;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
}
.carousel-indicators [data-bs-target]{
    border-top: 0;
    border-bottom: 0;
    height: 6px;
    width: 16px;
    border-radius: 8px;
    transition: all ease-out .3s;
    &.active{
        width: 32px;
    }
}
#c{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

form.searchform{
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, .15);
    padding-bottom: 2rem;
    input[type='text']{
        background: none;
        color: white;
        padding: .55rem 1rem;
        border: solid 1px $white;
        width: 100%;
        margin-right: 1px;
        border-right: none;
        border-radius: 8px;
        border-end-end-radius: 0;
        border-top-right-radius: 0;
    }
    button{
        border: none;
        background-color: $white;
        color: $body-bg;
        padding: .66rem 1.5rem;
        border-end-end-radius: 8px;
        border-top-right-radius: 8px;
    }
    input::-webkit-input-placeholder {
        color: #FFF;
    }
    input:-moz-placeholder {
        color: #FFF;
    }
    input::-moz-placeholder {
        color: #FFF;
    }
    input:-ms-input-placeholder {
        color: #FFF;
    }
}

/*lazy-image*/
.lazy-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
}
.lazy-image::before {
    content: "Cargando...";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.3s ease-out;
    font-size: 1.4rem;
}
.lazy-image img {
    display: block;
    max-width: 100%;
    height: auto;
    transform: translate(0,0) scale(1.2);
    opacity: 0;
    transition: transform .3s ease-out, opacity .3s ease-out;
}
.lazy-image.loaded::before {
    opacity: 0;
}
.lazy-image.loaded img {
    opacity: 1;
    transform: translate(0,0) scale(1);
}